import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WelcomePhoneComponent } from './welcome-phone.component';
import { NgScrollbarModule } from 'ngx-scrollbar'
import { MatRippleModule } from '@angular/material/core';

@NgModule({
  declarations: [
    WelcomePhoneComponent
  ],
  imports: [
    CommonModule,
    NgScrollbarModule,
    MatRippleModule
  ],
  exports: [
    WelcomePhoneComponent
  ]
})
export class WelcomePhoneModule { }
